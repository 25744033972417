<template>
  <div class="nav_container">
    <div class="content">
      <div class="inner">
        <div class="logo" style="width:260px;height:70px;" @click="gotoIndex">
          <img :src="logo" style="width:260px;height:70px;" alt="" />
        </div>
        <div class="search_wrap">
          <div class="serach_box">
            <el-input
              placeholder="支持药品名称/厂家/国药准字号/ERPID进行搜索"
              v-model="kw"
              @input="handleInput"
              @keyup.enter.native="handleEnter"
              clearable
              class="input-with-select"
              @clear="handleClear"
            >
            </el-input>
            <div class="box" v-if="listShow">
              <div v-for="item in arr" :key="item.value" @click="chooseItem(item)">
                {{ item.title }}
              </div>
            </div>
            <div class="search" @click="handleClick(kw)">搜索</div>
          </div>
          <div class="hot">
            热门搜索：
            <span v-for="item in list" :key="item.id" @click="handleClick(item.title)">
              {{ item.title }}
            </span>
          </div>
        </div>
        <div class="cart_box c_p" @click="$router.push('/index/cart')">
          <div class="img">
            <img src="@/assets/img/index/cart.png" alt="" />
          </div>
          <span style="margin-left: 8px">我的购物车</span>
          <span class="amount">{{ num }}</span>
        </div>
        <div class="call_box">
          <div class="img">
            <img :src="wximg" alt="" />
          </div>
          <div>
            <div>全国热线电话</div>
            <div class="num_box">
              <div class="call">
                <img src="@/assets/img/index/call.png" alt="" />
              </div>
              <span class="num">{{site_fwdh}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/tool.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      kw: "",
      list: [],
      handleInput: "",
      arr: [],
      listShow: false,
      logo: "",
      wximg: "",
      site_fwdh:"",
    };
  },
  computed: {
    ...mapGetters(["searchKey", "num"]),
  },
  watch: {
    searchKey(newVal, oldVal) {
      this.kw = newVal.kw;
      console.log(newVal, oldVal, "watch nav");
    },
    $route(to, from) {
      if (to.path == "/index/allGoods") return;
      if (to.path != from.path) {
        if (this.kw) {
          this.$store.commit("setSearchKey", { kw: "" });
        }
      }
    },
  },
  created() {
    this.$api("home.getHotkeywords").then((res) => {
      this.list = res.data;
    });
    this.$store.dispatch("getBadge");
    this.$api("home.getNavInfo").then((res) => {
      this.logo = res.data.settingImg.wz_logo;
      this.wximg = res.data.settingImg.app_img;
      this.site_fwdh = res.data.site_fwdh;
    });
  },
  mounted() {
    this.handleInput = debounce(this.getkw, 300);
  },
  methods: {
    gotoIndex() {
      this.$store.commit("CHANGE_TAB", 0);
      if (this.$route.path == "/index/home") return;
      this.$router.push("/index/home");
    },
    getkw() {
      if (!this.kw) return;
      this.$api("home.getSearch", { kw: this.kw }).then((res) => {
        this.arr = res.data;
        this.listShow = true;
      });
    },
    chooseItem(item) {
      this.kw = item.title;
      this.listShow = false;
      this.setKey();
      this.$bus.$emit("updateSearch", { kw: this.kw });
      this.go();
    },
    handleClick(title) {
      this.listShow = false;
      this.kw = title;
      this.setKey();
      this.$bus.$emit("updateSearch", { kw: this.kw });
      this.go();
    },
    handleEnter() {
      this.listShow = false;
      this.setKey();
      this.$bus.$emit("updateSearch", { kw: this.kw });
      this.go();
    },
    handleClear() {
      this.setKey();
    },
    go() {
      if (this.$route.path == "/index/allGoods") return;
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push("/index/allGoods");
    },
    setKey() {
      this.$store.commit("setSearchKey", { kw: this.kw });
    },
  },
};
</script>

<style lang="less" scoped>
.nav_container {
  height: 130px;
  background-color: #fff;
  .inner {
    display: flex;
    padding-top: 24px;
  }
  .logo {
    width: 248px;
    height: 67px;
  }
  .search_wrap {
    margin-top: 10px;
    margin-left: 58px;
  }
  .serach_box {
    width: 519px;
    height: 38px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    .input-with-select {
      flex: 1;
      height: 100%;
      border: 1px solid @themeColor;
      position: relative;

      ::v-deep .el-input__inner {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
    .box {
      z-index: 1000;
      position: absolute;
      top: 50px;
      background: #fff;
      width: 510px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      padding: 0 10px;
      max-height: 450px;
      overflow-y: auto;
      line-height: 1.6;
      div {
        cursor: pointer;
        &:hover {
          color: @themeColor;
        }
      }
    }
    .search {
      cursor: pointer;
      width: 99px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: @themeColor;
      font-size: 12px;
      color: #ffffff;
      border: 1px solid @themeColor;
    }
  }
  .hot {
    font-size: 12px;
    color: #666666;
    margin-top: 20px;
    span {
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        color: @themeColor;
      }
    }
  }
  .cart_box {
    margin: 0 20px;
    margin-top: 9px;
    width: 154px;
    height: 38px;
    border: 1px solid @themeColor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    .img {
      width: 17px;
      height: 17px;
    }
    .amount {
      display: inline-flex;
      height: 18px;
      padding: 0 4px;
      margin-left: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      background: @themeColor;
      border-radius: 100px;
    }
  }
  .call_box {
    margin-top: -18px;
    width: 190px;
    color: @baseBlue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    .img {
      width: 120px;
      height: 120px;
    }
    .num_box {
      display: flex;
      align-items: center;
    }
    .call {
      width: 14px;
      height: 14px;
    }
    .num {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
}
</style>
