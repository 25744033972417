<template>
  <div class="cate_container">
    <div class="content">
      <div class="cate_wrap">
        <div class="left" @click="handleCate">
          <div class="img">
            <img src="@/assets/img/index/cate.png" alt="" />
          </div>
          <span>商品分类</span>
          <div class="cate_wrap_goods" v-if="showcate">
            <goodsCate @change="change" />
          </div>
        </div>
        <div class="right">
          <!-- currentPath == item.url.url || activeTab == index -->
          <div class="item" v-for="(item, index) in cateList" :key="item.url.url" @click="handleCom(item, index)" :class="[activeTab == index ? 'active' : '']">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodsCate from "@/views/home/components/banner/goodsCate.vue";
import { debounce } from "@/utils/tool.js";
import { handleJump } from "@/utils";
export default {
  components: {
    goodsCate,
  },
  data() {
    return {
      currentPath: "",
      // activeTab: -1,
      activeTab: this.$store.state.tabIndex,
      cateList: [],
      showcate: false,
    };
  },
  watch: {
    $route(to) {
      console.log("route----to----", to);
      console.log("公共状态tabIndex---", this.$store.state.tabIndex);
      this.currentPath = to.path;
      this.activeTab = this.$store.state.tabIndex;
      this.showcate=false
    },
  },
  created() {
    this.currentPath = this.$route.path;
    if (this.currentPath == "/index/home") {// || this.currentPath == "/index/allGoods"
      this.showcate = false;
    }
    this.$api("home.getNavInfo").then((res) => {
      this.cateList = res.data.menuList;
    });
  },
  mounted() {
    window.addEventListener("scroll", debounce(this.handleScroll)); // 监听页面滚动
  },
  methods: {
    handleCom(item, ind) {
      console.log("tab点击----", item);
      this.$store.commit("CHANGE_PAGENAME", [item.title]);
      // if (item.url.url === this.currentPath) return;
      this.$store.commit("CHANGE_TAB", ind);
      handleJump(item.url);
      this.currentPath = item.url.url;
    },
    handleScroll() {
      let top = document.querySelector(".cate_container").getBoundingClientRect().top;
      if (top <= 0) {
        this.$emit("scollTop", true);
      } else {
        this.$emit("scollTop", false);
      }
    },
    // 控制首页是否允许收起打开
    handleCate() {
      if (this.currentPath !== "/index/home" ) {//&& this.currentPath !== "/index/allGoods"
        this.showcate = !this.showcate;
      }
    },
    change(bool) {
      this.showcate = bool;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.cate_container {
  height: 38px;
  background-color: #fff;
  border-bottom: 1px solid @themeColor;
  .cate_wrap {
    display: flex;
    .left {
      width: 200px;
      height: 38px;
      background: @themeColor;
      padding: 0 14px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      line-height: 16px;
      cursor: pointer;
      position: relative;
      .cate_wrap_goods {
        position: absolute;
        top: 38px;
        left: 0;
      }
      .img {
        width: 22px;
        height: 16px;
        margin-right: 12px;
      }
    }
    .right {
      width: calc(1200px - 190px - 200px);
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        &.active {
          font-weight: bold;
          color: @themeColor;
        }
        &:hover {
          color: @themeColor;
        }
      }
    }
  }
}
</style>
